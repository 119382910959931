import Carousel from "react-bootstrap/Carousel";
import "./Feature.css";
import { useTranslation } from "react-i18next";
import img1 from "../../assests/banner/shridi-egy-banner_1.jpg";
import img2 from "../../assests/banner/shridi-egy-banner_2.jpg";
import img3 from "../../assests/banner/shridi-egy-banner_3.jpg";
import "./Feature.css";
function Feature() {
  const { t } = useTranslation();

  return (
    <Carousel className="carouselInner carousel" data-bs-theme="dark">
      <Carousel.Item className="carouselItem">
        <img
          width={50}
          height={550}
          className="imageWidth d-block w-100"
          src={img1}
          alt="First slide"
        />
        <Carousel.Caption className="carouselCaption">
          <h5>{t("alShridi")}</h5>
          <p>{t("shridiText1")}</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="carouselItem">
        <img
          width={50}
          height={550}
          className="imageWidth d-block w-100"
          src={img2}
          alt="Second slide"
        />
        <Carousel.Caption className="carouselCaption">
          <h5>{t("alShridi")}</h5>
          <p>
            {t("shridiText2")}
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="carouselItem">
        <img
          width={50}
          height={550}
          className="imageWidth d-block w-100"
          src={img3}
          alt="Third slide"
        />
        <Carousel.Caption className="carouselCaption">
          <h5>{t("alShridi")}</h5>
          <p>
            {t("shridiText3")}

          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Feature;
