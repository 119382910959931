import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home/Home.jsx";
import Footer from "./Common/Footer/Footer";
import Product from "./Pages/Product/Product";
import ProductDescription from "./Pages/ProductDescription/ProductDescription";
import NavbarItem from "./Common/Navbar/Navbar";
import {useEffect} from "react";
import AOS from "aos";
import FloatingWhatsAppButton from './FloatingWhatsAppButton';

function App() {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
    <div className="App">
      <Router>
          <NavbarItem />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/product" element={<Product />} />
          <Route
            exact
            path="/ProductDescription"
            element={<ProductDescription />}
          />
        </Routes>
      <Footer />
      <FloatingWhatsAppButton /> 
      </Router>
    </div>
  );
}

export default App;
