import React, { useRef, useState } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import "aos/dist/aos.css";

const Result = () => {
  const { t } = useTranslation();

  return <p>{t('Thanks for contacting us!')}</p>;
};

const Contact = () => {
  const [result, showResult] = useState(false);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_25fygib",
        "template_xpmn3jk",
        form.current,
        "R7CdtcmXB6zwdnRde"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showResult(true);
  };

  const { t } = useTranslation();

  return (
    <div id="contact">
      <section
        className="section1 container my-2 p-2 shadow p-3 bg-white rounded"
        data-aos="fade-down"
      >
        <div className="container-fluid py-3">
          <header className="text-center pt-5">
            <h1 className="titleContact display-6">{t("contact_Us")}</h1>
          </header>
        </div>
        <form
          onSubmit={sendEmail}
          ref={form}
          className="row rowContact g-3 p-3"
        >
          <div className="col-md-4">
            <label htmlFor="validationDefault01" className="label form-label">
              {t("inputName")}
            </label>
            <input
              type="text"
              className="form-control"
              id="validationDefault01"
              placeholder={t("fullNamePlaceholder")}
              name="full_name"
              required
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputEmail4" className="label form-label">
              {t("Email")}
            </label>
            <input
              type="email"
              placeholder={t("emailPlaceholder")}
              className="form-control"
              id="inputEmail4"
              name="user_email"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="phone" className="label form-label">
              {t("phoneNumber")}
            </label>
            <input
              type="tel"
              placeholder={t("phoneNumberPlaceholder")}
              className="form-control"
              id="phone"
              required
              name="phone_number"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="inputAddress" className="label form-label">
              {t("adress")}
            </label>
            <input
              type="text"
              className="form-control"
              id="inputAddress"
              placeholder={t("adressPlaceholder")}
              name="address"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="inputContry" className="label form-label">
              {t("contry")}
            </label>
            <input
              type="text"
              placeholder={t("contryPlaceholder")}
              id="inputContry"
              className="form-control"
              name="contry"
            />
          </div>
          <div className="col-12">
            <label
              htmlFor="exampleFormControlTextarea1"
              className="label form-label"
            >
              {t("message")}
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="5"
              placeholder={t("messagePlaceholder")}
              name="message"
            ></textarea>
          </div>
          <div className="buttonContact col-12">
            <button type="submit" className="BTN btnCard">
              {t("sendNow")}
            </button>
            <div className="result">{result ? <Result /> : null}</div>
          </div>
        </form>
      </section>

      <div className="container">
        <div className="row rowContainer">
          <div className="map column col-lg-6 col-md" data-aos="fade-right">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d861.8363838803323!2d31.743039069633802!3d30.227221457189604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1457fb0de04d7c6f%3A0x2b9a84b0fd53670e!2sShridi%20Company!5e0!3m2!1sde!2sde!4v1691152953109!5m2!1sde!2sde"
              width="400"
              height="350"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="column contact col-lg-6 col-md" data-aos="fade-left">
            <div className="section-title">
              <br />
              <div className="subtitle">{t("getTouch")}</div>
            </div>
            <div className="contactItem">
              <div className="contactItem">
                <div className="d-block allItemContact">
                  <div className="ContactItems d-flex`}">
                    <i className="bi bi-geo-alt-fill iconItems"></i>
                    <div>
                      <p className="m-0">{t("adressDetails1")}</p>
                      <p className="m-0">{t("adressDetails2")}</p>
                    </div>
                  </div>
                  <div className="ContactItems d-flex`">
                    <i className="bi bi-telephone-fill iconItems"></i>
                    <p className="m-0">
                      <a className="linkContact" href="tel:+20 155 656 1269">
                        +20 155 656 1269
                      </a>
                      <br />
                      <a className="linkContact" href="tel:+20 155 656 1267">
                        +20 155 656 1267
                      </a>
                    </p>
                  </div>
                  <div className="ContactItems d-flex`">
                    <i className="bi bi-envelope-fill iconItems"></i>

                    <p className="m-0">
                      <a
                        className="linkContact"
                        href="mailto: info@shridi-egy.com"
                      >
                        info@shridi-egy.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
