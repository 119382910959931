import React from "react";
import './Quality.css'
import img1 from "../../assests/quality/shridi-egy-banner_1.jpg";
import img2 from "../../assests/quality/shridi-egy-banner_2.jpg";
import img3 from "../../assests/quality/shridi-egy-banner_3.jpg";
import { useTranslation } from "react-i18next";

import 'aos/dist/aos.css'

const Quality = () => {

    const { t } = useTranslation();

    return (
        <div id='products'>
            <div className="containerQuality" data-aos="fade-down">
                <div className="boxContainer row">
                    <div className="col-md-4 col-sm-6 col-12 p-0">
                        <div className="box">
                            <img
                                className="img"
                                src={img1}
                                width={480}
                                height={20}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12 p-0">
                        <div className="boxQuality">
                            <h1>{t("qaulity")}</h1>
                            <p>{t("qaulityText")}</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12 p-0">
                        <div className="box">
                            <img
                                className="img"
                                src={img2}
                                width={470}
                                height={90}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12 p-0">
                        <div className="boxAboutContact">
                            <h1>{t("contactTitle")}</h1>
                            <p>{t("contactText")}</p>
                            <div className="middle">
                                <a href="#contact" className="BTN">
                                    {t("contatBtn")}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12 p-0">
                        <div className="box">
                            <img
                                className="img"
                                src={img3}
                                width={380}
                                height={20}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12 p-0">
                        <div className="boxAboutContact">
                            <h1>{t("aboutTitle")}</h1>
                            <p>
                                {t("aboutText")}
                            </p>
                            <div className="middle">
                                <a href="#aboutUs" className="BTN">
                                    {t("aboutBtn")}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Quality
