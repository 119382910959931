import React, { useEffect } from 'react'
import AboutUs from './../../Components/AboutUS/AboutUs';
import Contact from './../../Components/Contact/Contact';
import Quality from './../../Components/Quality/Quality';
import Products from './../../Components/Products/Products';
import Feature from './../../Components/Feature/Feature';
import { useLocation } from 'react-router-dom';

const Home = () => {
  const location = useLocation();
  useEffect(() => {
    // Check if the URL contains a hash
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1)); // Remove the '#' from the hash
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  return (
    <>
      <Feature />
      <Quality />
      <Products />
      <AboutUs />
      <Contact />
    </>
  )
}

export default Home
