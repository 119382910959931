import React from "react";
import './Product.css'
import { useTranslation } from "react-i18next";
import productCard from './productCard';
import i18next from "i18next";

const Product = () => {
    const { t } = useTranslation();
    return (
        <div>
            <section className="mainTitle text-center">
                <div className="containerTitle">
                    <h1 className="title display-3">{t("productsTitle")}</h1>
                </div>
            </section>
            <section className="gallery" data-aos="fade-down">
                <div className="container cardSection">
                    <div className="row rowCard">
                        {productCard.map((card) => (
                            <div className="colProduct col-lg-4 mb-3 p-2 p-md-3 p-lg-4" key={card.id}>
                                <a href={`/ProductDescription?id=${card.id}`} className="containerCard">
                                    <div className="card">
                                        <div className="img-container">
                                            <img
                                                src={i18next.language == "en" ? card.imageSourceEn : card.imageSourceAr}
                                                className="card-img-top"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Product
