import React from "react";
import "./Products.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import chocolate_processing_ar from "../../assests/sections/shridi-egy-chocolate-processing-arabic.jpg";
import chocolate_processing_en from "../../assests/sections/shridi-egy-chocolate-processing-english.jpg";
import coating_ar from "../../assests/sections/shridi-egy-coating-arabic.jpg";
import coating_en from "../../assests/sections/shridi-egy-coating-english.jpg";
import cooling_ar from "../../assests/sections/shridi-egy-cooling-arabic.jpg";
import cooling_en from "../../assests/sections/shridi-egy-cooling-english.jpg";
import enrobing_ar from "../../assests/sections/shridi-egy-enrobing-arabic.jpg";
import enrobing_en from "../../assests/sections/shridi-egy-enrobing-english.jpg";
import forming_ar from "../../assests/sections/shridi-egy-forming-arabic.jpg";
import forming_en from "../../assests/sections/shridi-egy-forming-english.jpg";

const Products = () => {
  const { t } = useTranslation();

  return (
    <div id="products">
      <div className="containerProducts">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-6 col-sm-12 px-5 py-2 p-md-3 p-lg-4">
            <div className="card" data-aos="flip-right">
              <div className="view overlay">
                <a href="/ProductDescription?id=3">
                  <img
                    className="img card-img-top"
                    src={
                      i18next.language === "en"
                        ? chocolate_processing_en
                        : chocolate_processing_ar
                    }
                    alt="Card image cap"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 px-5 py-2 p-md-3 p-lg-4">
            <div className="card" data-aos="flip-right">
              <div className="view overlay">
                <a href="/ProductDescription?id=1">
                  <img
                    className="img card-img-top"
                    src={i18next.language === "en" ? enrobing_en : enrobing_ar}
                    alt="Card image cap"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 px-5 py-2 p-md-3 p-lg-4">
            <div className="card" data-aos="flip-right">
              <div className="view overlay">
                <a href="/ProductDescription?id=4">
                  <img
                    className="img card-img-top"
                    src={i18next.language === "en" ? forming_en : forming_ar}
                    alt="Card image cap"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 px-5 py-2 p-md-3 p-lg-4">
            <div className="card" data-aos="flip-right">
              <div className="view overlay">
                <a href="/ProductDescription?id=5">
                  <img
                    className="img card-img-top"
                    src={i18next.language === "en" ? coating_en : coating_ar}
                    alt="Card image cap"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 px-5 py-2 p-md-3 p-lg-4">
            <div className="card" data-aos="flip-right">
              <div className="view overlay">
                <a href="/ProductDescription?id=6">
                  <img
                    className="img card-img-top"
                    src={i18next.language === "en" ? cooling_en : cooling_ar}
                    alt="Card image cap"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 px-5 py-2 p-md-3 p-lg-4">
            <a href="/product">
              <div className="boxPro pointer" data-aos="flip-right">
                <h1>{t("products")}</h1>
                <p>{t("productsMore")}</p>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div id="aboutUs"></div>
    </div>
  );
};

export default Products;
