import React, { useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import "./ProductDescription.css";
import { useTranslation } from "react-i18next";
import productCard from '../Product/productCard';
import i18next from "i18next";

const ProductDescription = () => {

    const { t } = useTranslation();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const productId = urlSearchParams.get('id');
    const foundProduct = productCard.find(card => card.id == productId);
    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState(foundProduct.products[1].mainImage);
    const [modalTitle, setModalTitle] = useState(i18next.language == "en" ? foundProduct.products[1].modalTitleEn : foundProduct.products[1].modalTitleAr);

    const handleClose = () => setShow(false);
    const handleShow = (image, title) => {
        setSelectedImage(image);
        setModalTitle(title);
        setShow(true);
    }

    return (
        <div>
            <Modal contentClassName="width_720" className="modal" show={show} onHide={handleClose}>
                <Modal.Header className="modalHeader">
                    <CloseButton className="closeBtn" onClick={handleClose} />
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="imgModal">
                    <img alt="" src={selectedImage} style={{ width: '100%' }} className="imgModal" />
                    <a href={selectedImage} target="_blank">{t('Full screen')}  <i class="bi bi-arrows-fullscreen"></i></a>
                </Modal.Body>
            </Modal>

            {foundProduct ? foundProduct.products.map((subProduct, index) => (
                <div key={index}>
                    {index == 0 || index % 2 == 0 ?
                        <div className="rowProductDescription row rowSingleContainer">
                            <div className="contain1">
                                <div className="col-sm-6 ImageContainer">
                                    <div onClick={() => {
                                        handleShow(subProduct.mainImage, i18next.language == "en" ? subProduct.modalTitleEn : subProduct.modalTitleAr);
                                    }} className="imgPro">
                                        <img id={"imageRef_" + index}
                                            src={subProduct.mainImage}
                                            width={450} alt="no image"
                                            className="imageProduct"
                                        />
                                        {subProduct.galleryList ?
                                            <div className="imgBottom">
                                                {subProduct.galleryList.map((imageSource, imgIndex) => (
                                                    <img key={imgIndex} onClick={() => {
                                                        subProduct.mainImage = imageSource;
                                                        setSelectedImage(imageSource);
                                                        this.forceUpdate();
                                                    }} width={75} height={75} src={imageSource} className="" alt="" />
                                                ))}
                                            </div>
                                            : ""}
                                    </div>
                                </div>
                                <div className="singleProTitle1 col-sm-6">{i18next.language == "en" ? subProduct.titleEn : subProduct.titleAr}</div>
                            </div>
                            <div className="col-sm-6 sectionBottom"></div>
                            <div className="col-sm-6 widthSection">
                                <div className="itemPro">
                                    <p className="singleText">
                                        {i18next.language == "en" ? subProduct.descriptionEn : subProduct.descriptionAr}
                                        <br />
                                        <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="rowProductDescription row rowSingleContainer">
                            <div className="contain1">
                                <div className="title2 col-sm-6"> {i18next.language == "en" ? subProduct.titleEn : subProduct.titleAr}</div>
                                <div className="col-sm-6 ImageContainer">
                                    <div className="img2 imageleft">
                                        <img onClick={() => {
                                            handleShow(subProduct.mainImage, i18next.language == "en" ? subProduct.modalTitleEn : subProduct.modalTitleAr);
                                        }}
                                            width={450} src={subProduct.mainImage}
                                            className="imageProduct"
                                            alt="" />
                                        <div className="imgBottom">
                                            {subProduct.galleryList ?
                                                <div className="imgBottom">
                                                    {subProduct.galleryList.map((imageSource, imgIndex) => (
                                                        <img key={imgIndex} onClick={() => {
                                                            subProduct.mainImage = imageSource;
                                                            setSelectedImage(imageSource);
                                                            this.forceUpdate();
                                                        }} width={75} height={75} src={imageSource} alt="" />
                                                    ))}
                                                </div>
                                                : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 widthSection">
                                <div className="itemPro">
                                    <p className="singleText">
                                        {i18next.language == "en" ? subProduct.descriptionEn : subProduct.descriptionAr}
                                        <br />
                                        <br />
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6 sectionBottom"></div>
                        </div>
                    }
                </div>)) :
                <div>{t("noProductsFound")}</div>}
        </div>
    );
};

export default ProductDescription;
