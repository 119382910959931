import React from "react";
import "./AboutUs.css";
import { useTranslation } from "react-i18next";
import img from "../../assests/shridi-egy-logo.jpg";

const AboutUs = () => {
    const { t } = useTranslation();

    return (
        <div >
            <div className="containerAbout" id="aboutUs">
                <div>
                    <div className="aboutUs row">
                        <div className="about col-xl-8 col-lg-6 col-md" data-aos="fade-left">
                            <div className="aboutItem">
                                <div className="section-title">
                                    <br />
                                    <h1 className="subtitle h4">{t("aboutTitle")}</h1>
                                    <h2 className="title">
                                        <strong>{t("alShridi")}</strong>
                                    </h2>
                                </div>
                                <div className="allItemAbout">
                                    <div className="aboutItems">
                                        <i className="iconItems bi bi-check icon-baseline"></i>
                                        <p> {t("aboutText1")}</p>
                                    </div>
                                    <div className="aboutItems">
                                        <i className="iconItems bi bi-check icon-baseline"></i>
                                        <p> {t("aboutText2")}</p>
                                    </div>
                                    <div className="aboutItems">
                                        <i className="iconItems bi bi-check icon-baseline"></i>
                                        <p>{t("aboutText3")}</p>
                                    </div>
                                    <div className="aboutItems">
                                        <i className="iconItems bi bi-check icon-baseline"></i>
                                        <p>{t("aboutText4")}</p>
                                    </div>
                                    <div className="aboutItems">
                                        <i className="iconItems bi bi-check icon-baseline"></i>
                                        <p>{t("aboutText5")}</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="boximg col-xl-4 col-lg-6 col-md" data-aos="fade-right">
                            <div className="aboutBox">
                                <img
                                    className="image"
                                    alt=""
                                    src={img}
                                    width={350}
                                    height={350}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
};

export default AboutUs;
