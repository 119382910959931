import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.css";
import "./Navbar.css";
import logo from "../../assests/logo.png";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const NavbarItem = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "en"
  );

  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  const handleChange = (e) => {
    i18n.changeLanguage(e);
    setLanguage(e);
  };
  const { t } = useTranslation();

  return (
    <>
      <div className="containerr">
        <div className="rrow">
          <div className="logo">
            <img alt="#" src={logo} width="90" height="90" />
          </div>
          <div className="leftSide">
            <div className="containerItem">
              <div className="line item">
                <a
                  href={require("../../assests/shridi-egy-Catalog.pdf")}
                  download
                >
                  <i className="bi bi-file-earmark-arrow-down-fill iconItem"></i>
                </a>
                <div className="text">
                  <a
                    href={require("../../assests/shridi-egy-Catalog.pdf")}
                    download
                    className="bold"
                  >
                    {t("downloadCatalog")}
                  </a>
                </div>
              </div>
            </div>
            <div className="containerItem">
              <div className="line item">
                <i className="bi bi-envelope-fill iconItem"></i>
                <div className="text">
                  <p className="bold">{t("sendEmail")}</p>
                  <a href="mailto: info@shridi-egy.com">info@shridi-egy.com</a>
                </div>
              </div>
            </div>
            <div className="containerItem">
              <div className="line item">
                <i className="bi bi-telephone-fill iconItem"></i>
                <div className="text">
                  <p className="bold">{t("question")}</p>
                  <a href="tel:+201556561269">{t("number")}</a>
                </div>
              </div>
            </div>
            <div className="containerItem">
              <div className="line item">
                <i className="bi bi-stopwatch-fill iconItem"></i>
                <div className="text">
                  <p className="bold">{t("workHour")}</p>
                  <p>{t("work")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Navbar collapseOnSelect expand="lg" variant="dark" className="navbar">
        <Container>
          <Navbar.Toggle className="toggle" aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                active={window.location.pathname == "/"}
                className="link"
                href="/"
              >
                {t("home")}
              </Nav.Link>
              <Nav.Link className="link" href="/#aboutUs">
                {t("about")}
              </Nav.Link>
              <Nav.Link className="link" href="/#contact">
                {t("contact")}
              </Nav.Link>
              <Nav.Link
                active={
                  window.location.pathname.includes("product") ||
                  window.location.pathname.includes("ProductDescription")
                }
                className="link"
                href="/product"
              >
                {t("products")}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <div href="#">
            <div>
              <section>
                <a
                  className="btn btn-floating m-1"
                  href="https://www.facebook.com/people/Shridi-Company/100093065813092/"
                  target="_blank"
                  role="button"
                >
                  <i className="bi bi-facebook text-white"></i>
                </a>
                <a
                  className="btn btn-floating m-1"
                  href="https://wa.me/+201556561269"
                  target="_blank"
                  role="button"
                >
                  <i className="bi bi-whatsapp text-white"></i>
                </a>

                <a
                  className="btn btn-floating m-1"
                  href="https://www.instagram.com/shridi_company?igsh=MXF6enFsYWdpcGRxYg=="
                  target="_blank"
                  role="button"
                >
                  <i className="bi bi-instagram text-white"></i>
                </a>
                <a className="btn btn-floating m-1 slected-box">
                  <div>
                    {language === "en" ? (
                      <button
                        className="btn text-white"
                        onClick={() => {
                          handleChange("ar");
                        }}
                      >
                        <i className="bi bi-globe2"></i>
                        {" العربية "}
                      </button>
                    ) : (
                      <button
                        className="btn"
                        onClick={() => {
                          handleChange("en");
                        }}
                      >
                        <i className="bi bi-globe2"></i>
                        {" English "}
                      </button>
                    )}
                  </div>
                </a>
              </section>
            </div>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarItem;
