const productCard = [
  {
    id: 1,
    imageSourceEn: require("../../assests/001/shridi-egy-enrobing-english.jpg"),
    imageSourceAr: require("../../assests/001/shridi-egy-enrobing-arabic.jpg"),
    products: [
      {
        id: 1,
        titleAr: "خط تغطيس الشوكولاتة",
        titleEn: "Chocolate Enrobing Line",
        descriptionAr:
          "تُستخدم لتغطية المنتجات بالشوكولاتة بشكل مثالي. يتضمن خط التغطيس الغطاسة ونفق تبريد بطول 8 متر. متوفر بأحجام مختلفة من 30 سم حتى 1000 سم . تمكِّنك من تغطية المنتج بشكل كامل أو سفلي أو نصفي ",
        descriptionEn:
          "It is used to perfectly coat products with chocolate. The dipping line includes a dipping tank and an 8-meter long cooling tunnel. It is available in various sizes ranging from 30 cm to 1000 meters. It enables you to fully coat the product or coat it partially, either from the bottom or halfway.",
        mainImage: require("../../assests/001/shridi_egy_chocolate_enrobing_line.png"),
        modalTitleEn: "Chocolate Enrobing Line",
        modalTitleAr: "خط تغطيس الشوكولاتة",
        galleryList: [],
      },
      {
        id: 2,
        titleAr: "سير تغطيس الشوكولاتة",
        titleEn: "Chocolate Enrobing",
        descriptionAr:
          "تُستخدم لتغطية المنتجات بالشوكولا بواسطة سير تغطيس. يمكن ربط السير مع ماكينة صبابة شوكولا موديل ناعورة أو ماكينة تعديل الشوكولا تيمبرنغ . متوفر بمقاسات متنوعه من 20 سم حتى 50 سم. يمكِّنك من تغطية المنتج بشكل كامل أو سفلي أو نصفي.",
        descriptionEn:
          "It is used to coat products with chocolate using a dipping conveyor. The conveyor can be connected to a chocolate pouring machine of the 'Naourah' model or a chocolate tempering machine. It is available in sizes ranging from 20 cm to 50 cm. It allows you to fully coat the product or coat it partially, either from the bottom or halfway.",
        mainImage: require("../../assests/001/shridi_egy_chocolate_enrobing.png"),
        modalTitleEn: "Chocolate Enrobing",
        modalTitleAr: "سير تغطيس الشوكولاتة",
        galleryList: [],
      },
      {
        id: 3,
        titleAr: "ماكينة ديكور الشوكولاتة ",
        titleEn: "Chocolate Decoration",
        descriptionAr:
          "تقوم الماكينة بتزيين ورسم قطع الشوكولا او قوالب الكيك بعده اشكال ورسوم مختلفة لاضفاء طابع جمالي على المنتج متوفره بعده مقاسات 20 سم  - 40 سم – 60 سم حسب الطلب و يمكن إضافتها إلى سير تغطيس او نفق تبريد.",
        descriptionEn:
          "The machine is used to decorate and draw on chocolate pieces or cake molds with various shapes and designs, adding an aesthetic touch to the product. It is available in several sizes, such as 20 cm, 40 cm, and 60 cm, and can be customized according to requirements. It can also be integrated into a dipping line or cooling tunnel.",
        mainImage: require("../../assests/001/shridi_egy_chocolate_decoration.png"),
        modalTitleEn: "Chocolate Decoration",
        modalTitleAr: "ماكينة ديكور الشوكولاتة ",
        galleryList: [],
      },
      {
        id: 4,
        titleAr: "نافورة الشوكولاته",
        titleEn: "Chocolate Fountain",
        descriptionAr:
          "نافورة الشوكولا هي جهاز يستخدم لخلق تدفق مستمر وجذاب من الشوكولا المنصهرة، وذلك لتوفير تجربة تناول ممتعة ولذيذة في الأحداث والمناسبات. تُوضع ماكينة نافورة الشوكولاتة في مختلف الأماكن والأحداث، مثل حفلات الزفاف، الحفلات الخاصة، و حتى في الفنادق والمطاعم الراقية.",
        descriptionEn:
          "A chocolate fountain is a device used to create a continuous and appealing flow of melted chocolate, providing a delightful and delicious experience at events and occasions. Chocolate fountain machines are placed in various locations and events, such as weddings, private parties, and even in upscale hotels and restaurants.",
        mainImage: require("../../assests/001/shridi_egy_fountain.png"),
        modalTitleEn: "Chocolate Fountain",
        modalTitleAr: "نافورة الشوكولاته",
        galleryList: [],
      },
      {
        id: 5,
        titleAr: "ماكينه رش قوالب الشوكولا",
        titleEn: "Chocolate Spry Gun",
        descriptionAr:
          "تستخدم الماكينه لتزيين قوالب الشوكولا بالالوان والرسوم المميزه قبل اتمام عمليه الصب لاضافه طابع جمالي للمنتج",
        descriptionEn:
          "The machine is used to decorate chocolate molds with unique colors and designs before the pouring process, adding an aesthetic touch to the product.",
        mainImage: require("../../assests/001/shridi_egy_chocolate_spry_gun.png"),
        modalTitleEn: "Chocolate Spry Gun",
        modalTitleAr: "ماكينه رش قوالب الشوكولا",
        galleryList: [],
      },
    ],
  },
  {
    id: 2,
    imageSourceEn: require("../../assests/002/shridi-egy-moulding-english.jpg"),
    imageSourceAr: require("../../assests/002/shridi-egy-moulding-arabic.jpg"),
    products: [
      {
        id: 1,
        titleAr: "معدلة شوكولا 50 كغ",
        titleEn: "Chocolate Tempering 50 kg",
        descriptionAr:
          "تُستخدم لتعديل حرارة الشوكولا بدقة والتحكم في درجتها لتحقيق نتائج مثالية. متوفرة بعدة أحجام من 20 كغ وحتى 60 كغ، مع إمكانية ربطها مع سير تغطيس و نفق تبريد",
        descriptionEn:
          "It is used to precisely adjust and control the temperature of chocolate to achieve optimal results. It is available in several sizes ranging from 20 kg up to 60 kg, with the option to connect it to a enrober machine and cooling tunnel",
        mainImage: require("../../assests/002/shridi_egy_chocolate_tempering.png"),
        modalTitleEn: "Chocolate Tempering 50 kg",
        modalTitleAr: "معدلة شوكولا 50 كغ",
        galleryList: [],
      },
      {
        id: 2,
        titleAr: "ماكينة صب الشوكولا سعة 10 كغ",
        titleEn: "Chocolate Wheel 10 Kg",
        descriptionAr:
          "ماكينه صب شوكولا سعة 10  كغ موديل ناعوره مزودة بهزاز يتسع لقالب واحد فقط لاماكينه شل القالب",
        descriptionEn:
          "The 10 kg capacity chocolate pouring machine, model 'Naourah', is equipped with a vibrator It also has the capability to shall  the mould",
        mainImage: require("../../assests/002/shridi_egy_chocolate_moulding_naoora_10kg.png"),
        modalTitleEn: "Chocolate Wheel 10 Kg",
        modalTitleAr: "ماكينة صب الشوكولا سعة 10 كغ",
        galleryList: [],
      },
      {
        id: 3,
        titleAr: "خط صب شوكولا ون شوت ",
        titleEn: "One Shot Depositor line",
        descriptionAr:
          "خط صب شوكولا ون شوت لونين ون شوت تصمم حسب شكل الحبة المطلوب مؤلف من ملقم قوالب + صبابه ون شوت - ساده  + هزاز لاتمام عمليه الصب بنجاح",
        descriptionEn:
          "A dual-color chocolate one shot pouring line is designed according to the desired shape of the bean. It consists of a mold feeder, a dual-color chocolate shot depositor, a plain pouring nozzle, and a vibrator to ensure successful pouring process",
        mainImage: require("../../assests/002/shridi_egy_chocolate_deposter_line_oneshot.png"),
        modalTitleEn: "One Shot Depositor line",
        modalTitleAr: "خط صب شوكولا ون شوت ",
        galleryList: [],
      },
      {
        id: 4,
        titleAr: "خط صب شوكولا نصف أوتوماتيك ",
        titleEn: "Chocolate Moulding Line Semi Automatic shell",
        descriptionAr:
          "يستخدم هذا الخط لعمل قشره على قالب الشوكولا مع امكانية فصل عمليه شل القالب لامكانية الصب الساده الخط مزود بماكينه تيمبرنغ شوكولا وممكن ربطه مع صبابه شوكولا موديل ناعوره في حالات الصب بالشوكولا الساخنه",
        descriptionEn:
          "This line is used to create a shell on the chocolate mold, with the possibility of separating the mold shall process for plain pouring. The line is equipped with a chocolate tempering machine and can be connected to a chocolate wheeling  machine 'Naourah' model for hot chocolate cases",
        mainImage: require("../../assests/002/shridi_egy_chocolate_moulding_line_semi_automatic.png"),
        modalTitleEn: "Chocolate Moulding Line Semi Automatic shell",
        modalTitleAr: "خط صب شوكولا نصف أوتوماتيك ",
        galleryList: [],
      },
      {
        id: 5,
        titleAr: "خط صب شوكولا",
        titleEn: "Chocolate Moulding Line",
        descriptionAr:
          "خط صب شوكولا موديل ناعورة يستخدم الخط لصب قوالب الشوكولا متعددة الأشكال يتألف الخط من ملقم قوالب – سخان – مساحة – هزاز ",
        descriptionEn:
          "The Naoura chocolate molding line is used for pouring chocolate molds. The line consists of a mold feeder, a heater, a cleaning area, and a vibrator",
        mainImage: require("../../assests/002/shridi_egy_chocolate_moulding_line.png"),
        modalTitleEn: "Chocolate Moulding Line",
        modalTitleAr: "خط صب شوكولا",
        galleryList: [],
      },
      {
        id: 6,
        titleAr: "صبابة شوكولا دروبس / كوينز",
        titleEn: "Chocolate Coins - Drops Line",
        descriptionAr:
          "يتضمن الخط صبابة شوكولا مع ماكينة تيمبرينغ (شوكولا باردة) دروبس أو كوينز بأحجام وأوزان متعددة. مزوده بنفق تبريد بطول 8 متر",
        descriptionEn:
          "The chocolate pouring line includes a chocolate depositor with a tempering machine (cold chocolate), capable of producing drops or coins of various sizes and weights. It is equipped with an 8m  cooling tunnel",
        mainImage: require("../../assests/002/shridi_egy_chocolate_coins_drops_line.png"),
        modalTitleEn: "Chocolate Coins - Drops Line",
        modalTitleAr: "صبابة شوكولا دروبس / كوينز",
        galleryList: [],
      },
      {
        id: 7,
        titleAr: "ماكينة تعبئة شوكولا",
        titleEn: "Chocolate Filling",
        descriptionAr:
          "ماكينة تعبئة شوكولا بمطربانات. متوفرة بأحجام وسعات متعددة، ويمكن صب لونين في نفس المطربان حسب الطلب",
        descriptionEn:
          "The chocolate filling machine with hoppers is available in customizable sizes and capacities. It allows for pouring two colors simultaneously in the same hopper",
        mainImage: require("../../assests/002/shridi_egy_chocolate_filling.png"),
        modalTitleEn: "Chocolate Filling",
        modalTitleAr: "ماكينة تعبئة شوكولا",
        galleryList: [],
      },
      {
        id: 8,
        titleAr: "ماكينة صب الشوكولا سعة 50 كغ",
        titleEn: "Chocolate Wheel 50 Kg",
        descriptionAr:
          "ماكينه صب شوكولا سعه 55 كغ موديل ناعوره مع هزاز يتسع ل 2 قوالب شوكولا لاماكينه شل القالب مع امكانيه اضافتها على خط تغطيس او اضافه سير تغطيس عليها وربطها مع تونيل تبريد",
        descriptionEn:
          "The 50 kg capacity chocolate pouring machine, model 'Naourah', is equipped with a vibrator and can accommodate 2 chocolate molds. It has the capability to shall the mould and connect it to a enrobing  line or link it with a cooling tunnel",
        mainImage: require("../../assests/002/shridi_egy_chocolate_moulding_naoora_50kg.png"),
        modalTitleEn: "Chocolate Wheel 50 Kg",
        modalTitleAr: "ماكينة صب الشوكولا سعة 50 كغ",
        galleryList: [],
      },
      {
        id: 9,
        titleAr: "طاولة صب شوكولا",
        titleEn: "Chocolate Table Moulding",
        descriptionAr:
          "الطاولة مصممة لصب الشوكولا بحوضين، مع هزاز بينهما. تُستخدم لصب قوالب الشوكولا يدويًا، متوفرة باحجام متعددة، مع إمكانية ربطها مع مجموعة طحن",
        descriptionEn:
          "The table is designed for manually pouring chocolate into two basins, with a vibrator in between. It is used for manual pouring of chocolate molds and is available in various sizes. It can also be connected to a grinding unit",
        mainImage: require("../../assests/002/shridi_egy_chocolate_moulding_manuel.png"),
        modalTitleEn: "Chocolate Table Moulding",
        modalTitleAr: "طاولة صب شوكولا",
        galleryList: [],
      },
    ],
  },
  {
    id: 3,
    imageSourceEn: require("../../assests/003/shridi-egy-chocolate-processing-english.jpg"),
    imageSourceAr: require("../../assests/003/shridi-egy-chocolate-processing-arabic.jpg"),
    products: [
      {
        id: 1,
        titleAr: "ماكينة طحن شوكولا",
        titleEn: "Chocolate Ball Mill",
        descriptionAr:
          "تقوم هذه الماكينة بطحن حُبوب الكاكاو ومكونات الشوكولاتة الأخرى، مثل السكر والزبدة الكاكاو، لتكوين عجينة شوكولاتة سلسة ومتجانسة. تتوفر هذه الماكينات بتصاميم وأحجام متعددة. تأتي بسعات تتراوح بين 5 كغ وحتى 2 طن",
        descriptionEn:
          "This machine is used for grinding cocoa beans and other chocolate ingredients such as sugar and cocoa butter to form a smooth and homogeneous chocolate paste. These machines are available in various designs and sizes. They come in capacities ranging from 5 kg up to 2 tons",
        mainImage: require("../../assests/003/shridi_egy_ball_mill_1000kg.png"),
        modalTitleEn: "Chocolate Ball Mill",
        modalTitleAr: "ماكينة طحن شوكولا",
        galleryList: [],
      },
      {
        id: 2,
        titleAr: "ماكينة تسييح الزبدة",
        titleEn: "Fat Melting Tank",
        descriptionAr:
          "تُستخدم لتسهيل عملية تذويب وتسييح الزبدة بسرعة، وذلك لتسريع عملية تحضير الشوكولاتة. تأتي بشكل اسطواني أو بشكل موديل حرف 'U' مزودة بمضخة شوكولاتة لسحب المكون إلى ماكينة الطحن، ويمكن أيضًا السحب يدويًا حسب الحاجة. تتوفر بسعات متنوعة من 200 كغ وحتى 2 طن.",
        descriptionEn:
          "It is used to facilitate the rapid melting and tempering of butter, thereby speeding up the chocolate preparation process. It comes in a cylindrical shape or in a 'U' model, equipped with a chocolate pump to transfer the ingredient to the grinding machine. It can also be manually operated as per the requirement. They are available in various capacities ranging from 200 kg up to 3 tons",
        mainImage: require("../../assests/003/shridi_egy_butter_tank_heater.png"),
        modalTitleEn: "Fat Melting Tank",
        modalTitleAr: "ماكينة تسييح الزبدة",
        galleryList: [],
      },
      {
        id: 3,
        titleAr: "ماكينة تنك شوكولا",
        titleEn: "Chocolate Tank",
        descriptionAr:
          "تُستخدم لحفظ الشوكولاتة بعد عملية الطحن. مع مضخة شوكولاتة أو بدون ، ويمكن أيضًا التفريغ يدويًا حسب الحاجة. تتوفر بسعات متنوعة من 50 كغ وحتى 5 طن",
        descriptionEn:
          "The machine is used for storing chocolate after the grinding process. It can be equipped with a chocolate pump or without one, and manual discharge is also possible as needed. It is available in various capacities ranging from 50 kg up to 3 tons",
        mainImage: require("../../assests/003/shridi_egy_chocolate_tank.png"),
        modalTitleEn: "Chocolate Tank",
        modalTitleAr: "ماكينة تنك شوكولا",
        galleryList: [],
      },
      {
        id: 4,
        titleAr: "ماكينة طبخ كراميل - توفي و ملبن",
        titleEn: "caramel & toffee malbn cocker",
        descriptionAr:
          "تُستخدم لطبخ حشوات الكراميل أو التوفي أو اللبان، وتقوم بعملية التسخين عن طريق البخار أو الزيت أو الغاز حسب الطلب. متوفرة بعدة مقاسات من 25 كغ حتى 200 كغ",
        descriptionEn:
          "The machine is used for cooking caramel or toffee fillings and it utilizes steam, oil, or gas heating methods according to the specific requirements. It is available in various sizes ranging from 25 kg to 200 kg",
        mainImage: require("../../assests/003/shridi_egy_gas_cocker.png"),
        modalTitleEn: "caramel & toffee malbn cocker",
        modalTitleAr: "ماكينة طبخ كراميل - توفي و ملبن",
        galleryList: [],
      },
      {
        id: 4,
        titleAr: "ماكينة طبخ حشوات أوتوماتيك سعة 100 كغ",
        titleEn: "caramel & toffee malbn cocker automatic 100 kg",
        descriptionAr:
          "تستخدم الماكينة لطبخ حشوات النوغا او الكراميل او اللبان تعمل عن طريق البخار او الزيت الحراري حسب  الطلب متوفرة بعدة مقاسات",
        descriptionEn:
          "The machine is used for cooking nougat, caramel, or toffee fillings. It operates through steam or thermal oil, depending on the specific requirements",
        mainImage: require("../../assests/003/shridi_egy_malban_cocker.png"),
        modalTitleEn: "caramel & toffee malbn cocker automatic 100 kg",
        modalTitleAr: "ماكينة طبخ حشوات أوتوماتيك سعة 100 كغ",
        galleryList: [],
      },
    ],
  },
  {
    id: 4,
    imageSourceEn: require("../../assests/004/shridi-egy-forming-english.jpg"),
    imageSourceAr: require("../../assests/004/shridi-egy-forming-arabic.jpg"),
    products: [
      {
        id: 4,
        titleAr: "ماكينة تشكيل حشوات",
        titleEn: "Forming Machine",
        descriptionAr:
          "ماكينة تشكيل حشوات بالدوران تستخدم لتشكيل عجينة التمر -  عجينة البسكويت - عجينة حوز الهند. يمكن حفر اي شكل على القالب كما يمكن حفر اكثر من شكل على نفس القالب مع امكانيه تغيير القالب لتغيير الشكل عن طريق قالب اضافي, متوفرة بعدة مقاسات من 40 سم حتى 100سم",
        descriptionEn:
          "The rotating filling shaping machine is used to shape fillings such as date paste, biscuit dough, and coconut paste. It allows for engraving any shape on the mold, and multiple shapes can be engraved on the same mold. Additionally, it is possible to change the mold by using an additional mold. It is available in several sizes, ranging from 40 cm to 100 cm",
        mainImage: require("../../assests/004/shridi_egy_forming_bar.png"),
        modalTitleEn: "Forming Machine",
        modalTitleAr: "ماكينة تشكيل حشوات",
        galleryList: [],
      },
      {
        id: 5,
        titleAr: "(أحجار البحر)  كسارة شوكولا",
        titleEn: "chocolate crusher (Sea stones)",
        descriptionAr:
          "تقوم الماكينة بتكسير الشوكولا إلى قطع صغيرة قبل عمليه التلبيس يمكن استخدام شوكولا بألوان مختلفة حسب المنتج المراد تصنيعه",
        descriptionEn:
          "The machine is used to break down chocolate into small pieces before the tempering process. It allows for the use of chocolate with different colors, depending on the desired product to be manufactured",
        mainImage: require("../../assests/004/shridi_egy_chocolate_sea_stone_forming.png"),
        modalTitleEn: "chocolate crusher (Sea stones)",
        modalTitleAr: "(أحجار البحر)  كسارة شوكولا",
        galleryList: [],
      },
      {
        id: 6,
        titleAr: "ماكينة تشكيل فارماسيل الشوكولا",
        titleEn: "Varmasil Forming",
        descriptionAr:
          "تقوم الماكينة بتشكيل قطع واشكال اسطوانية من الشوكولا تستخدم لتزيين قطع الحلوى و قوالب الكيك متوفرة بسعات واحجام متنوعة حسب الطلب",
        descriptionEn:
          "The machine is used to shape cylindrical pieces of chocolate that are used for decorating candies and cake molds. It is available in various capacities and sizes according to specific requirements",
        mainImage: require("../../assests/004/shridi_egy_varmasil.png"),
        modalTitleEn: "Varmasil Forming",
        modalTitleAr: "ماكينة تشكيل فارماسيل الشوكولا",
        galleryList: [],
      },
      {
        id: 7,
        titleAr: "خفاقة موديل حرف U",
        titleEn: "Mixing Machine",
        descriptionAr:
          "تستخدم  الماكينه لخلط المواد عبر اكس حلزوني الشكل حسب المنتج المراد استعماله على الماكينة",
        descriptionEn:
          "The machine is used for mixing ingredients using an axial spiral shape, depending on the product intended to be used on the machine",
        mainImage: require("../../assests/004/shridi_egy_mixer.png"),
        modalTitleEn: "Mixing Machine",
        modalTitleAr: "خفاقة موديل حرف U",
        galleryList: [],
      },
      {
        id: 8,
        titleAr: "كسارة مكسرات",
        titleEn: "NUT CRUSHER",
        descriptionAr:
          "تقوم الماكينه بتكسير وتفتيت البندق او المكسرات الى قطع صغيره",
        descriptionEn:
          "The machine is designed to crush and grind hazelnuts or other nuts into small pieces ",
        mainImage: require("../../assests/004/shridi_egy_nut_crusher.png"),
        modalTitleEn: "NUT CRUSHER",
        modalTitleAr: "كسارة مكسرات",
        galleryList: [],
      },
    ],
  },
  {
    id: 5,
    imageSourceEn: require("../../assests/005/shridi-egy-coating-english.jpg"),
    imageSourceAr: require("../../assests/005/shridi-egy-coating-arabic.jpg"),
    title: "ball mil5",
    text: "cardTextProduct5",
    products: [
      {
        id: 1,
        titleAr: "ماكينة  تشكيل شوكولا (سمارتيز)",
        titleEn: "Smarties Chocolate Molding line ",
        descriptionAr:
          "ماكينة تشكيل شوكولا سمارتيز تحفر حسب الشكل مطلوب متوفرة بعدة مقاسات",
        descriptionEn:
          "Smarties Chocolate Molding Machine is used to engrave chocolate in the desired shape. It is available in multiple sizes",
        mainImage: require("../../assests/004/shridi_egy_smarties_forming.png"),
        modalTitleEn: "Smarties Chocolate Molding line ",
        modalTitleAr: "ماكينة  تشكيل شوكولا (سمارتيز)",
        galleryList: [],
      },
      {
        id: 2,
        titleAr: "طاسة تلبيس",
        titleEn: "Coating Pan ",
        descriptionAr:
          "تُستخدم لتلبيس وتغليف المنتجات بالسكر، وهي مجهزة بمحرك هواء بارد ونظام تسخين. يتوفر تصميم لتلميع المنتجات حسب الطلب. متاحة بأشكال وسعات مختلفة تتراوح من 20 كغ حتى 100 كغ",
        descriptionEn:
          "It is used for sugar coating and enveloping products, equipped with a cold air motor and a heating system. There is a design available for polishing the products as per requirements. It is available in various shapes and capacities ranging from 20 kg to 100 kg",
        mainImage: require("../../assests/004/shridi_egy_coating_pan.png"),
        modalTitleEn: "Coating Pan ",
        modalTitleAr: "طاسة تلبيس",
        galleryList: [],
      },
      {
        id: 3,
        titleAr: "ماكينة تشكيل موديل دراجيه",
        titleEn: "Chocolate Dragee",
        descriptionAr:
          "تُستخدم لتشكيل المنتجات بالشوكولات، وتتوفر بسعات مختلفة حسب الطلب، مع إمكانية ربطها بمجموعة طحن الشوكولا",
        descriptionEn:
          "It is used for molding chocolate products and is available in different capacities according to demand. It can also be connected to a chocolate grinding unit",
        mainImage: require("../../assests/004/shridi_egy_dragee_forming.png"),
        modalTitleEn: "Chocolate Dragee",
        modalTitleAr: "ماكينة تشكيل موديل دراجيه",
        galleryList: [],
      },
    ],
  },
  {
    id: 6,
    imageSourceEn: require("../../assests/006/shridi-egy-cooling-english.jpg"),
    imageSourceAr: require("../../assests/006/shridi-egy-cooling-arabic.jpg"),
    title: "ball mil6",
    text: "cardTextProduct6",
    products: [
      {
        id: 1,
        titleAr: "نفق التبريد",
        titleEn: "Cooling Tunnel",
        descriptionAr:
          "نفق تبريد مُصمم بشكل مثالي لضمان تبريد فعَّال للمنتجات. متوفر بأحجام وأطوال مختلفة حسب الطلب. متوفر بأطوال مختلفة تبدأ من  4 متر وحتى 18 متر",
        descriptionEn:
          "The cooling tunnel is designed perfectly to ensure efficient cooling of the products. It is available in various sizes and lengths according to custom requirements. It is available in different lengths starting from 4 meters up to 18 meters",
        mainImage: require("../../assests/006/shridi_egy_cooling_tunnel.png"),
        modalTitleEn: "Cooling Tunnel",
        modalTitleAr: "نفق التبريد",
        galleryList: [],
      },
      {
        id: 2,
        titleAr: "تشيلر تبريد",
        titleEn: "Chiller Cooling",
        descriptionAr:
          "يُستخدم لتبريد المياه داخل مجموعة الطحن او لعملية تبريد السلندر السمارتيز . متوفر بقدرات تبريد مختلفة حسب الطلب ",
        descriptionEn:
          "It is used for cooling water inside the grinding unit or for the cooling process of the Smarties cylinder. It is available in different cooling capacities according to demand",
        mainImage: require("../../assests/006/shridi_egy_chiller_cooling.png"),
        modalTitleEn: "Chiller Cooling",
        modalTitleAr: "تشيلر تبريد",
        galleryList: [],
      },
    ],
  },
];

export default productCard;
