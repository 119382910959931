import React, { useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useTranslation } from "react-i18next";

const FloatingWhatsAppButton = () => {
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(false);

  const handleClick = () => {
    window.open('https://wa.me/+201556561269', '_blank');
  };

  const styles = {
    container: {
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      display: 'flex',
      alignItems: 'center',
      zIndex: 1000,
      cursor: 'pointer',
      backgroundColor: '#28a3dc',
      borderRadius: '50px',
      transition: 'width 0.8s ease-in-out, padding 0.5s ease-in-out, box-shadow 0.5s ease-in-out',
      width: hovered ? 'auto' : '60px',
      padding: hovered ? '10px 20px' : '10px',
      boxShadow: hovered ? '0 6px 20px rgba(0,0,0,0.3)' : '0 2px 10px rgba(0,0,0,0.2)',
      overflow: 'hidden',
    },
    iconContainer: {
      width: '40px',
      height: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      color: '#fff',
      fontSize: '30px',
      padding: '5px',
      animation: 'pulse 2s infinite',
    },
    text: {
      marginLeft: '10px',
      color: '#fff',
      fontSize: '16px',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      transition: 'opacity 0.8s ease-in-out, transform 0.8s ease-in-out',
      opacity: hovered ? 1 : 0,
      transform: hovered ? 'scale(1)' : 'scale(0)',
    },
    '@media (max-width: 768px)': {
      container: {
        width: hovered ? '200px' : '50px',
        padding: hovered ? '8px 15px' : '8px',
      },
      icon: {
        fontSize: '25px',
      },
      text: {
        fontSize: '14px',
      },
    },
  };

  return (
    <div
      style={styles.container}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleClick}
    >
      <style>
        {`
          @keyframes pulse {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.2);
            }
            100% {
              transform: scale(1);
            }
          }
        `}
      </style>
      <div style={styles.iconContainer}>
        <i className="bi bi-whatsapp" style={styles.icon}></i>
      </div>
      <span style={styles.text}>{t("WhatsApp")}</span>
    </div>
  );
};

export default FloatingWhatsAppButton;
