
import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <MDBFooter className="bgColor text-center text-lg-start">
      <section className="sectionLine">
        <MDBContainer className="text-center footer">
          <MDBRow>
            <MDBCol md="4" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <MDBIcon icon="gem" className="me-3" />
                {t("alShridi")}
              </h6>
              {i18next.language === "en" ? (
                <p className="text-left">{t("alshridiFooter")}</p>
              ) : (
                <p className="text-right">{t("alshridiFooter")}</p>
              )}
            </MDBCol>

            <MDBCol md="4" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4 ">
                {t("usefulLinks")}
              </h6>
              <p>
                <a href="/" className="text-reset">
                  {t("home")}
                </a>
              </p>
              <p>
                <a href="/#aboutUs" className="text-reset" >
                  {t("about")}
                </a>
              </p>
              <p>
                <a href="/#contact" className="text-reset">
                  {t("contact")}
                </a>
              </p>
              <p>
                <a href="/product" className="text-reset">
                  {t("products")}
                </a>
              </p>
            </MDBCol>

            <MDBCol md="4" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">{t("contact")}</h6>
              <div className="allItem">
                <div className="item">
                  <i className="me-3 bi bi-geo-alt-fill icon align-self-start"></i>
                  <div className="">
                    <p className="m-0">{t("adressDetails1")}</p>
                    <p>{t("adressDetails2")}</p>
                  </div>
                </div>
                <div className="item">
                  <i className="me-3 bi bi-envelope-fill icon align-self-start"></i>
                  <a className="powered" href="mailto: info@shridi-egy.com">info@shridi-egy.com</a>
                </div>
                <div className="item">
                  <i className="me-3 bi bi-telephone-fill icon align-self-start"></i>{" "}
                  <a className="powered" href="tel:+20 155 656 1269">{t("number")}</a>

                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
      <div className="container fbottom align-item-center">
        <div className="col-md-6 col-lg-6">
          <p>
            © {new Date().getFullYear()} All Rights Reserved by: SHRIDI COMPANY
          </p>
        </div>
        <div className="col-md-6 col-lg-6">
          <div className="text-center text-md-right">
            <p>
              Powered by : 
              <a className="powered" href="https://www.hisham-mosajo.com/">
                Hisham Mosajo
              </a>
            </p>
          </div>
        </div>
      </div>
    </MDBFooter>
  );
}
